'use strict';

angular.module('common')
    .factory('CommonService', CommonService);

CommonService.$inject = ['$http', '$q', 'baseUrlConstant'];

function CommonService($http, $q, baseUrlConstant) {

    var baseUrl = baseUrlConstant;

    var service = {
        baseUrl: baseUrl,
        getAppInfo: getAppInfo,
        authenticateProfile: authenticateProfile,
        logout: logout,
        loadOrganization: loadOrganization,
        loadOrganizationUsers: loadOrganizationUsers,
        changeDefaultOrganization: changeDefaultOrganization,
        changePassword: changePassword,
        saveProfile: saveProfile,
        getAllPermissions: getAllPermissions,
        getAllRoles: getAllRoles,
        getSystemMessages: getSystemMessages
    };

    function getAppInfo(force) {
        if (service.appInfo && !force) {
            return $q.resolve(service.appInfo);
        }
        return service.appInfo = $http.get(baseUrl + '/api/common/v1/aeRosAppInfo')
            .then(function(response) {
                return response.data;
            });
    }

    function authenticateProfile(email, password, forcedLogin) {
        return $http.post(baseUrl + "/api/common/v1/authenticateProfile", { email: email, password: password, forcedLogin: !!forcedLogin });
    }

    function logout() {
        return $http.post(baseUrl + "/user/web/logout");
    }

    function loadOrganization(organizationId) {
        return $http.get(baseUrl + "/organization/" + organizationId);
    }

    function loadOrganizationUsers(organizationId) {
        return $http.get(baseUrl + "/organization/" + organizationId + "/users");
    }

    function changeDefaultOrganization(organizationId) {
        return $http.put(baseUrl + "/organization/default/", {
            id: +organizationId
        });
    }

    function changePassword(username) {
        return $http.post(baseUrl + "/organization/generatePasswordResetToken", {
            username: username
        });
    }

    function saveProfile(organizationId, user) {
        return $http.post(baseUrl + '/organization/' + organizationId + '/user/settings', user);
    }

    function getAllPermissions() {
        return $http.get(baseUrl + '/api/permissions');
    }

    function getAllRoles() {
        return $http.get(baseUrl + "/api/roles");
    }


    function getSystemMessages() {
        return $http.get(baseUrl + "/admin/api/message/active");
    }

    return service;
}
